import { Component, OnInit, Input, SimpleChanges, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators} from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PasswordValidation } from '@shared/validators/password-validator';
import { UserService } from '@shared/services/user.service';
import { UserPasswordDetails } from '@shared/interfaces/common';
import { DialogService } from '@shared/services/dialog.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent implements OnInit {

  @Input() userId;

  form = new FormGroup({
    id: new FormControl(''),
    oldPassword: new FormControl('', [Validators.required]),
    newPassword: new FormControl('', [Validators.required, Validators.minLength(8)]),
    confirmPassword: new FormControl('', [Validators.required])
  },
  {validators: PasswordValidation.MatchPassword }
  );
oldPasswordError:boolean=false;
 userPassWordDetails={} as UserPasswordDetails
  constructor(
    public toastrService: ToastrService,
    public userService:UserService,
    private dialogService: DialogService
  ) { }

  ngOnInit() {
  
    this.userPassWordDetails={} as UserPasswordDetails;
    
  }
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeSidePanel();
  }
  get e() {
    return this.form.controls;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['userId']) {    
      this.userPassWordDetails.UserId = changes['userId'].currentValue;
      this.e['id'].setValue(this.userPassWordDetails.UserId);
    this.form.controls['id'].setValue(this.userId)
    }
  }

  closeSidePanel() {
    if (this.form.dirty) {
      this.dialogService.confirm('Confirm', 'You have unsaved changes. Do you want to ignore the changes?').subscribe(res => {
        if (res) {
          this.clearForm();
        }
      })
    } else {
      this.clearForm();
    }
  }
  clearForm() {
    $('body').removeClass('show-sidepanel');
    $('.side-panel-right-password').removeClass('active');
    this.form.reset();
    this.form.controls['id'].setValue(this.userId);
  }


  onSubmit() {
    this.form.value.id=this.userPassWordDetails.UserId;
    this.userPassWordDetails.ConfirmNewPassword=this.form.value.confirmPassword;
    this.userPassWordDetails.NewPassword=this.form.value.newPassword;
    this.userPassWordDetails.OldPassword=this.form.value.oldPassword;
    this.userPassWordDetails.UserId= this.userId;
    this.userPassWordDetails.IsAgree=true;
    this.userService.changePassword(this.userPassWordDetails).subscribe(result=>
    {
      if (result.result.status == "success") {
        if(result.result.data)
        {
          //this.oldPasswordError=false;
          this.form.reset();
          this.closeSidePanel();
          this.toastrService.success('Password updated successfully.', 'Success!')
        }
        else
        {
          this.form.controls.oldPassword.setErrors({'incorrect': true})
          //this.oldPasswordError=true;
        }
       
      }    
    })
  }

}



