import { Observable } from 'rxjs';

export interface DashboardData {
  type: number;
  // UserData: {
  //   TotalCustomers: number;
  //   ActiveCustomer: number;
  //   InactiveCustomer: number;
  //   loggedCustomers: number;
  // };
  // ContractData: {
  //   TotalContracts: number;
  //   NetInvetsment: number;
  //   TimeDeliquent: number;
  //   AccountRecievable: number;
  // };
}

export interface dashboardCount {
  MNewContract: number;
  MDisposedContract: number;
  YNewContract: number;
  YDisposedContract:number;
  type: number;
  syncTime: string;
}

export interface Company {
  CustomerCompany: string;
  CompanyId: number;
}

export interface ListItem {
  Id: number;
  Name: string;
}

export interface ListItemTemp {
  id: number;
  name: string;
}

export interface DocumentInfo {
  file: string;
  ObjectType: ObjectType;
  FileName: string;
  FileType: FileType;
  ObjectId: number;
}

export enum ObjectType {
  None = 0,
  Banner = 1,
  FormsAndDocuments = 2,
  UserProfile = 3,
  Requests = 4
}

export enum FileType {
  None = 0,
  Document = 1,
  Image = 2
}

export interface DownloadZip {
    filename:string[];
    id:number;
    objectType:ObjectType;
}

export interface Address {
  id: number;
  Street: string;
  State: string;
  Country: string;
  City: string;
  Zip: string;
  Address:string;
}

export interface UserPasswordDetails {
  UserId: number;
  OldPassword: string;
  NewPassword: string;
  ConfirmNewPassword: string;
  IsAgree: boolean;
}

export class CommonCriteria {
  SearchKey: string;
  SortColumn: string;
  SortDirection: string;
  PageIndex: number;
  PageSize: number;
}

export enum NotificationType {
  None = 0,
  ForgotPasswordEmail = 1,
  LoginDetailsEmail = 2,
  ResendLoginDetailsEmail = 3,
  ResetPasswordEmail = 4,
  AdditionalFinancingNECEmail = 5,
  HelpRequestNECEmail = 6,
  CustomerProfileEditNECEmail = 8,
  PaymentApprovalDelayEmail = 9,
  PaymentApprovalConfirmationEmail = 10,
  PaymentApprovalRejection = 11
}

export enum TransactionFeeType {
  None = 0,
  Amount = 1,
  Percent = 2
}

export enum PaymentType {
  None = 0,
  Card = 1,
  ACH = 2
}

export enum TransactionStatus {
    None = 0,
    AUTHORIZATION_EXPIRED = 1,
    AUTHORIZED = 2,
    AUTHORIZING = 3,
    FAILED = 4,
    GATEWAY_REJECTED = 5,
    PROCESSOR_DECLINED = 6,
    SETTLED = 7,
    SETTLING = 8,
    SUBMITTED_FOR_SETTLEMENT = 9,
    VOIDED = 10,
    UNRECOGNIZED = 11,
    SETTLEMENT_CONFIRMED = 12,
    SETTLEMENT_DECLINED = 13,
    SETTLEMENT_PENDING = 14
}

export enum SignUPStatus {
  None = 0,
  System_Approved = 1,
  Admin_Approved = 2,
  Rejected = 3,
  Pending = 4
}

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean | Observable<boolean>;
}
