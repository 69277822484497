import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { FaqService } from '@shared/services/faq.service';
import { ToastrService } from 'ngx-toastr';
import { FaqComponent } from '@app/modules/admin/faq/faq.component';
import { BannerService } from '@shared/services/banner.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CustomerService } from '@shared/services/customer.service';
import { SignUPStatus } from '@shared/interfaces/common';

@Component({
  selector: 'app-alerts',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.css']
})
export class AlertsComponent implements OnInit {
  onDiscrad = new EventEmitter();
  isFaqDeleted: string;
  constructor(
    public dialogRef: MatDialogRef<AlertsComponent>,
    public faqService: FaqService,
    public toastrService: ToastrService,
    private BannerService: BannerService,
    private customerService: CustomerService,
    private spinner: NgxSpinnerService,
    // public faqComponent:FaqComponent,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {
    this.faqService.currentDeleteStatus.subscribe(message => this.isFaqDeleted = message);
  }

  closeDialog() {
    this.dialogRef.close();
  }

  discardChanges() {
    this.onDiscrad.emit();
    this.closeDialog();
  }

  deleteBanner(banner) {
    this.spinner.show();
    this.BannerService.deleteBanner(banner.id).subscribe(result => {
      if (result.result.status == "success") {
        this.dialogRef.close(true);
        this.spinner.hide();
        this.toastrService.success('Banner deleted successfully!', 'Success!');

      }
      else {
        this.spinner.hide();
        this.dialogRef.close(false);
        this.toastrService.error('Banner deletion failed!', 'Error!');
      }

    });

  }

  deleteFAQ(faq) {
    this.spinner.show();
    this.closeDialog();
    this.faqService.deleteFaq(faq.id).subscribe(result => {
      if (result.result.status == "success") {
        this.spinner.hide();
        this.dialogRef.close(true);
        this.toastrService.success('FAQ deleted successfully!', 'Success!');
        this.faqService.changeDeleteStatus("true");
      }
      else {
        this.spinner.hide();
        this.dialogRef.close(false);
        this.toastrService.error("Could not delete the FAQ, please try deleting it after sometime.", 'Error!');
      }

    });
  }

  contractConfirmPay(data?:any) {
    this.toastrService.success('Payment successful!', 'Success!');
    this.dialogRef.close();
  }

  contractConfirmBuy(data?:any){
    this.toastrService.error("We couldn't process your buyout request. Please try again after sometime.", 'Error!');
    this.dialogRef.close();
  }

  financeRequestConfirm(data?:any) {
    this.toastrService.success('Request sent successfully..!', 'Success!');
    this.dialogRef.close();
  }

  updateRequestStatus(element: any, selector: string) {
    this.spinner.show();
    let requestStatus = selector === 'approve' ? SignUPStatus.Admin_Approved : SignUPStatus.Rejected ;
    let params = {
      id: element.id,
      signUpStatus: requestStatus
    }
    this.customerService.updateSignupCustomerStatus(params).subscribe(result => {
      if (result.result.status == "success") {
        this.toastrService.success('Status udated successfully!', 'Success!');
      } else {
        this.toastrService.error("Could not update the status, please try again later.", 'Error!');
      }
      this.dialogRef.close(true);
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.dialogRef.close(true);
      if(error.message=='InvalidCustomer'){
        this.toastrService.error('Customer Account Number does not exist', "Error!");
      }
      else if(error.message=='DuplicateEmail')
              this.toastrService.error("It looks like this email is already in use. Please try with another email ID.", "Error!");
      else
      {
        this.toastrService.error('Something went wrong!', 'Error!');
      }
      
    });

  }

}
