export class AppConsts {

    static remoteServiceBaseUrl = 'http://neccp.fingent.net/';
    static appBaseUrl = 'http://cpweb.fingent.net/';
    static appBaseHref: string; // returns angular's base-href parameter value if used during the publish
 
    static localeMappings: any = [];

    static readonly userManagement = {
        defaultAdminUserName: 'admin'
    };

    static readonly localization = {
        defaultLocalizationSourceName: 'CustomerPortal'
    };

    
    static readonly authorization = {
        encrptedAuthTokenName: 'enc_auth_token'
    };
}
