// "Production" enabled environment
//https://cpapi.neclease.com/

export const environment = {
  production: true,
  hmr: false,
  appConfig: 'appconfig.json',
  baseUrl: 'https://cpapi.neclease.com/',
  timeOutDuration: 1, // in days
  S3StorageService: true,
  GATrackingId: 'G-XRY2LP9N51'
};