import { Component, OnInit, Input, SimpleChanges, EventEmitter, Output, HostListener } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '@shared/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from '@shared/services/user.service';
import { CustomerUserDto } from '@shared/interfaces/nec-user';
import { DocumentInfo, Address, FileType, ObjectType } from '@shared/interfaces/common';
import { UserDto } from '@shared/interfaces/nec-user';
import { NgxSpinnerService } from 'ngx-spinner';
//import { Address } from 'cluster';
import { environment } from 'environments/environment';
import { blankSpaceFirst } from '@shared/validators/blank-space-validator';
import { CommonService } from '@shared/services/common.service';
import { DialogService } from '@shared/services/dialog.service';
@Component({
  selector: 'app-profile-edit',
  templateUrl: './profile-edit.component.html',
  styleUrls: ['./profile-edit.component.css']
})
export class ProfileEditComponent implements OnInit {
  @Input() editData;
  public emailExpression = "[a-zA-Z0-9_\\.\\+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-\\.]+";

  editCustomerProfile = new FormGroup({
    firstName: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    lastName: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    contactNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
    emailId: new FormControl('', [Validators.required, Validators.email, Validators.pattern(this.emailExpression)]),
    title: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    contactNumberExt: new FormControl('')
  });

  editForm = new FormGroup({
    id: new FormControl('', [Validators.required]),
    firstName: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    lastName: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    title: new FormControl('', [Validators.required, blankSpaceFirst.blankSpaceFirst]),
    contactNumber: new FormControl('', [Validators.required, Validators.minLength(10)]),
    role: new FormControl('', [Validators.required]),
    street: new FormControl('', [blankSpaceFirst.blankSpaceFirst]),
    state: new FormControl('', [blankSpaceFirst.blankSpaceFirst]),
    country: new FormControl('',[blankSpaceFirst.blankSpaceFirst]),
    city: new FormControl('',[blankSpaceFirst.blankSpaceFirst]),
    zip: new FormControl(''),
    userImage: new FormControl(''),
    contactNumberExt: new FormControl('')
  });
  public mobileNumberMask = ['(', /[0-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  baseImageUrl = environment.baseUrl + 'Images/UserProfile/';
  roles;
  emailAddress: string;
  isDirty = false;
  userDto: CustomerUserDto;
  imageDocument: DocumentInfo;
  documentId: number;
  imageRemover: boolean;
  uploadedDocuments: any = [];
  @Output() profileUpdate: EventEmitter<any> = new EventEmitter<any>();
  @Output() customerProfileUpdate: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    private apiService: ApiService,
    public toastrService: ToastrService,
    private UserService: UserService,
    private spinner: NgxSpinnerService,
    private commonService: CommonService,
    private dialogService: DialogService
  ) {
    //this.apiService.getAsObservable('./assets/mock-files/mock-roles.json').subscribe(data => {
    //  this.roles = data;

    //})
    this.getRoles();
  }

  ngOnInit() {
    this.userDto = {} as CustomerUserDto;
    this.UserService.IsDirtyClear.subscribe((paymentDetails: object[]) => {
      this.closeSidePanel();
    });
  }

  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.closeSidePanel();
  }

  detectFiles(event) {
    let files = event.target.files;
    if (files) {
      var i = 0;
      let allowed = ['image/x-png', 'image/gif', 'image/jpeg', 'image/jpg', 'image/png'];
      var error = false;
      for (let file of files) {
        if (allowed.indexOf(file.type) !== -1) {
          this.imageReader(file).then(x => {
            this.imageDocument = {} as DocumentInfo;
            this.imageDocument.file = x.replace(/^data:image\/[a-z]+;base64,/, '');
            this.imageDocument.FileName = file.name;
            this.imageDocument.FileType = FileType.Image;
            var userDetails = this.editForm.value;
            this.imageDocument.ObjectId = userDetails.id;
            this.imageDocument.ObjectType = ObjectType.None;
            this.imageRemover = false;
          });
          i++;
        } else {
          /* not allowed extensions */
          error = true;
        }
      }
    }
    if (error) {
      this.toastrService.error('only images are allowed', 'Error!');
    }
  }

  imageReader(file): Promise<any> {
    return new Promise<any>((resoleve, reject) => {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = (e: any) => {
        const dataUrl = e.target.result;
        let fileDetails = {
          url: dataUrl,
          name: file.name,
          preview: 0,
          userfile: file
        };
        this.uploadedDocuments = fileDetails;
        resoleve(reader.result);
      };

      reader.onerror = x => {
        reject(x);
      };
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.editData = changes['editData'].currentValue;
    this.setSidePannal();
  }

  setSidePannal() {
    this.editForm.controls['id'].setValue(this.editData.id);
    this.editForm.controls['firstName'].setValue(this.editData.name);
    this.editForm.controls['lastName'].setValue(this.editData.surname);
    this.editForm.controls['title'].setValue(this.editData.title);
    this.editForm.controls['contactNumber'].setValue(this.editData.phoneNumber);
    this.editForm.controls['role'].setValue(this.editData.roleId);
    this.editForm.controls['contactNumberExt'].setValue(this.editData.phoneExtension);
    if (this.editData.address) {
      this.editForm.controls['street'].setValue(this.editData.address.street);
      this.editForm.controls['state'].setValue(this.editData.address.state);
      this.editForm.controls['country'].setValue(this.editData.address.country);
      this.editForm.controls['city'].setValue(this.editData.address.city);
      this.editForm.controls['zip'].setValue(this.editData.address.zip);
    }
    this.emailAddress = this.editData.emailAddress;
    this.documentId = this.editData.documentId;
    this.imageDocument = null;
    if (this.editData.document) {
      let tempImage = {
        url: environment.S3StorageService?this.editData.document.url: this.baseImageUrl + this.editData.document.thumbImageFileName,
        name: this.editData.document.displayName,
        preview: 0,
        userfile: ''
      };
      this.uploadedDocuments = tempImage;
    }
  }
  checkIsDirty() {
    if (this.editForm.dirty) this.isDirty = !this.isDirty;
    else this.closeSidePanel();
  }
  onIsDirtyCancelClick() {
    this.isDirty = false;
  }
  closeSidePanel() {
    if (this.editForm.dirty) {
      this.dialogService
        .confirm('Confirm', 'You have unsaved changes. Do you want to ignore the changes?')
        .subscribe(res => {
          if (res) {
            this.clearForm();
          }
        });
    } else {
      this.clearForm();

    }
  }

  clearForm() {
    this.editForm.reset();
    $('body').removeClass('show-sidepanel');
    $('.side-panel-right').removeClass('active');
    this.setSidePannal();
    $('.side-panel-right').scrollTop(0);
    this.uploadedDocuments=[];
  }

  get e() {
    return this.editForm.controls;
  }

  getRoles() {
    this.UserService.GetAdminRoles().subscribe(data => {
      this.roles = data.result.data;
    });
  }

  removeImage() {
    this.uploadedDocuments = [];
    this.imageDocument = {} as DocumentInfo;
    this.imageRemover = true;
    this.editForm.controls['userImage'].setValue('');
  }
  numberOnly(event: any, flag: string) {
    return this.commonService.numberOnly(event, flag);
  }

  onSubmit() {
    // if("profileData?.roleNames[0]=='NECMaster' || profileData?.roleNames[0]=='NECAdmin' || profileData?.roleNames[0]=='NECSupport'") {
    if (
      this.editData.roleNames[0] == 'NECMaster' ||
      this.editData.roleNames[0] == 'NECAdmin' ||
      this.editData.roleNames[0] == 'NECSupport'
    ) {
      this.editForm.valid;
      var userDetails = this.editForm.value;
      var userAddDetails = {} as UserDto;
      userAddDetails.address = {} as Address;
      userAddDetails.id = userDetails.id;
      userAddDetails.firstName = userDetails.firstName.trim();
      userAddDetails.lastName = userDetails.lastName.trim();
      userAddDetails.emailAddress = this.emailAddress;
      userAddDetails.title = userDetails.title.trim();
      userAddDetails.phoneNumber = userDetails.contactNumber;
      userAddDetails.roleId = userDetails.role;
      userAddDetails.address.Street = userDetails.street?userDetails.street.trim():'';
      userAddDetails.address.State = userDetails.state?userDetails.state.trim():'';
      userAddDetails.address.Country = userDetails.country?userDetails.country.trim():'';
      userAddDetails.address.City = userDetails.city?userDetails.city.trim():'';
      userAddDetails.address.Zip = userDetails.zip?userDetails.zip.trim():'';
      userAddDetails.documentInfo = this.imageDocument;
      userAddDetails.documentId = this.documentId;
      userAddDetails.userImageIsDeleted = this.imageRemover;
      userAddDetails.PhoneExtension = userDetails.contactNumberExt;
      this.spinner.show();
      this.UserService.UpdateUser(userAddDetails).subscribe(data => {
        this.spinner.hide();
        if (data != '0') {
          this.profileUpdate.emit();
          this.editForm.reset();
          this.toastrService.success('Profile details updated successfully.', 'Success!');
          this.clearForm();
        }
      });
    }

    if (this.editData.roleNames[0] == 'CustomerUser' || this.editData.roleNames[0] == 'CustomerAdmin') {
      this.spinner.show();
      var userDetails = this.editForm.value;
      var userAddDetails = {} as UserDto;
      userAddDetails.address = {} as Address;
      userAddDetails.id = userDetails.id;
      userAddDetails.firstName = userDetails.firstName.trim();
      userAddDetails.lastName = userDetails.lastName.trim();
      userAddDetails.emailAddress = this.emailAddress;
      userAddDetails.title = userDetails.title.trim();
      userAddDetails.phoneNumber = userDetails.contactNumber;
      userAddDetails.roleId = userDetails.role;
      if (this.editData.addressId) {
        userAddDetails.addressId = this.editData.addressId;
        userAddDetails.address.id = this.editData.addressId;
      }
      userAddDetails.address.Street = userDetails.street?userDetails.street.trim():'';
      userAddDetails.address.State = userDetails.state?userDetails.state.trim():'';
      userAddDetails.address.Country = userDetails.country?userDetails.country.trim():'';
      userAddDetails.address.City = userDetails.city?userDetails.city.trim():'';
      userAddDetails.address.Zip = userDetails.zip?userDetails.zip.trim():'';
      userAddDetails.documentInfo = this.imageDocument;
      userAddDetails.documentId = this.documentId;
      userAddDetails.userImageIsDeleted = this.imageRemover;
      userAddDetails.PhoneExtension = userDetails.contactNumberExt;
      this.spinner.show();
      this.UserService.UpdateCustomerUser(userAddDetails).subscribe(data => {
        if (data != '0') {
          if (data.result.status == 'success') {
            this.customerProfileUpdate.emit();
            this.spinner.hide();
            this.editForm.reset();
            this.clearForm();
            this.toastrService.success('User details updated successfully!', 'Success!');
          } else {
            this.spinner.hide();
            this.editForm.reset();
            this.clearForm();
            this.toastrService.warning("Oops something went wrong, it's not you it is us! We are working on getting it back.", 'danger!');
          }
        } else {
          this.spinner.hide();
          this.toastrService.warning('Sorry!. Email is already registered !', 'Error!');
        }
        this.spinner.hide();
      });
      // this.toastrService.success('Profile details updated successfully.', 'Success!')
      this.clearForm();
    }
  }
}
