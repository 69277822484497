import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { DatatableOptions } from '@shared/models/datatable';

import { LoginService } from './login.service';
import { RestService } from './rest.service';
import { UserDto } from '@shared/interfaces/nec-user';
import { CustomerUpdateDetails ,NonPortalCustomerAddDto } from '@shared/interfaces/customer';


@Injectable()
export class CustomerService extends RestService {
    cutsomerId: BehaviorSubject<number> = new BehaviorSubject(0);
    isCustomerEdited: BehaviorSubject<null> = new BehaviorSubject(null);
    emitCustomerCount = new EventEmitter<any>();
    constructor(_http: HttpClient, loginService: LoginService) {
        super(_http, loginService);
    }

    getFilteredCustomers(filter: string) {
        return this.GET('Customer/GetCustomers?customerName=' + filter);
    }

    getCustomers(params: HttpParams) {
        return this.GET('Customer/GetAll', params);
    }
    
    getCustomerUsers(params: HttpParams) {
        return this.GET('User/GetCustomerUsers', params);
    }

    changeCustomerStatus(customerId: number) {
        return this.PUT('Customer/Deactivate?customerId=' + customerId, '');
    }

    getCustomerDetails(id: number) {
        return this.GET('Customer/Get?id=' + id);
    }

    getCustomerContracts(params: HttpParams) {
        return this.GET('Customer/GetCustomerContract', params);
    }

    ChangeUserStatus(userId: number) {
        return this.PUT('User/ChangeUserStatus?userId=' + userId, '');
    }

    ChangeCustomerUserStatus(userId: number) {
        return this.PUT('User/ChangeCustomerUserStatus?userId=' + userId, '');
    }
    
    deleteUsers(userId: number) {
        return this.DELETE('User/Delete?id=' + userId);
    }
    
    CustomerUserDelete(userId: number) {
        return this.DELETE('User/CustomerUserDelete?id=' + userId);
    }

    getCustomerCount() {
        var newDate = new Date();
        var offset = newDate.getTimezoneOffset();
        return this.GET('Customer/GetCustomersCount?timeZone='+offset);
    }

    UpdateCustomerUser(userDetails: UserDto) {
        return this.PUT('User/UpdateCustomerUser', userDetails);
    }

    getContractCount(cutsomerId: number) {
        return this.GET('Customer/GetCustomerContractsCount?customerId=' + cutsomerId);
    }

    UpdateCustomer(custDetails: any) {
        return this.PUT('Customer/Update', custDetails);
    }

    SendLoginDetails(userId: any) {
        return this.PUT('User/SendLoginDetails?userId='+userId,'');
    }

    getCustomerProfileDetails(customerId: number) {
        return this.GET('User/SendLoginDetails?userId='+customerId);
    }

    getRequestFiledType() {
        return this.GET('Customer/GetCustomerEditFields');
    }   

    getUserCustomerDetails(id: number) {
    return this.GET('Customer/GetCutomerDetails?id=' + id);
    }

    getCustomerEditRequest(index: number, pageSize: number) {
    return this.GET('Customer/GetCustomerEditRequests?pageIndex=' + index+'&pageSize='+pageSize);
    }

    updateCustomerUser(userDetails: any) {
        return this.PUT('Customer/Update', userDetails);
    }

    getCustomerSignUP(params: HttpParams) {
        return this.GET('CustomerSignUP/GetAll', params);
    }

    getCustomerSignUPDetails(id: any) {
        return this.GET('CustomerSignUP/Get?Id='+id);
    }

    updateSignupCustomerStatus(params: any) {
        return this.PUT('CustomerSignUP/UpdateStatus', params);
    }

    getCustomerCCANDetails(params: HttpParams) {
        return this.GET('CustomerSignUP/GetCustomers?CCAN='+params);
    }

    updateSignedCustomerDetails(params: any) {
        return this.PUT('CustomerSignUP/UpdateSignUprequest', params);
    }
    updateInvoiceMailOpt(ccan: number,isAutomail:boolean) {
        return this.PUT('Customer/UpdateInvoiceMailOpt?ccan='+ccan+'&isAutomail='+isAutomail,'');
    }

  downloadSignUpCustomerDetails(options: DatatableOptions) {
    let params = new HttpParams()
      .append('SortColumn', options.sortColumn)
      .append('SortDirection', options.sortDirection)
      .append('SearchKey', options.searchKey)
      .append('PageIndex', options.pageIndex.toString())
      .append('PageSize', options.pageSize.toString())

    if (options.filters) {
      options.filters.forEach(filter => {
        params = params.append('filter.' + filter.key, filter.value ? filter.value : '');
      })
    }
    return this.GET('CustomerSignUP/DownloadSignUpDetails', params);
  }

  getNonPortalCustomers(params: HttpParams) {
    return this.GET('Customer/GetAllNonPortalCustomers', params);
  }
  createNonPortalCustomer(nonPortalCustomer:NonPortalCustomerAddDto )
  {
       return this.POST('Customer/CreateNonPortalCustomer',nonPortalCustomer);  
  }
  getTransactionFee(nonPortalCustomer:NonPortalCustomerAddDto )
  {
       return this.POST('Customer/GetTransactionFee',nonPortalCustomer);  
  }
}
