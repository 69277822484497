import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { NavItem } from '../nav-item';
import { Router } from '@angular/router';
import { NavService } from '../nav.service';
import { menuAnimations } from '@shared/animations/activity-log-animations';
import { Subscription } from 'rxjs';
import { AdminDynamicHeaderService } from '@shared/services/admin-dynamic-header.service';
import { CommonService } from '@shared/services/common.service';
@Component({
  selector: 'app-menu-list-item',
  templateUrl: './menu-list-item.component.html',
  styleUrls: ['./menu-list-item.component.scss'],
  animations: [menuAnimations]
})
export class MenuListItemComponent implements OnInit {
  expanded: boolean;
  activeMenu: string;
  expandedMenu: string;
  propertiesSubscription: Subscription;
  // private userPermission: Subscription;
  menuPermisions: any = [];
  tempMenu: any[] = [];
  @HostBinding('attr.aria-expanded') ariaExpanded = this.expanded;
  @Input() item: NavItem;
  @Input() depth: number;

  constructor(
    public navService: NavService,
    private dynamicHeader: AdminDynamicHeaderService,
    public router: Router,
    public commonService: CommonService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
    }
  }

  ngOnInit() {
    this.navService.currentUrl.subscribe((url: string) => {
      if (this.item.route && url) {
        this.expanded = url.indexOf(`/${this.item.route}`) === 0;
        this.ariaExpanded = this.expanded;
      }
      this.checkItemOpened(url, this.item);
    });
    this.propertiesSubscription = this.dynamicHeader.properties.subscribe(updatedProperties => {
      if (updatedProperties.activeMenu) {
        /* to set the active menu (submenu or main menu) in case of reload */
        this.activeMenu = updatedProperties.activeMenu;
      }
      if (updatedProperties.expandedMenu) {
        /* to set the expanded menu (main menu) in case of reload  -----------  mainly for sub menu inner pages */
        this.commonService.expandedMenu = updatedProperties.expandedMenu;
      }
    });
    if (this.item && this.item.children && this.item.children.filter(e => e.menuName === this.activeMenu).length > 0) {
      /* to set the expanded menu  ------------------  for sub menu*/
      this.commonService.expandedMenu = this.item.menuName;
    }
  }

  checkItemOpened(activeUrl, item) {
    /* find the expanded menu when navigating through side menu */
    if (item.children && item.children.length && activeUrl) {
      if (item.children.filter(e => e.route === activeUrl).length > 0) {
        this.expanded = true;
      }
    }
  }

  onItemSelected(item: NavItem) {
    /* navigating throgh side menu */
    if (this.commonService.expandedMenu == item.parentMenu && !this.depth) {
      this.commonService.expandedMenu = '';
    } else {
      if (item.children && item.children.length) {
        this.commonService.expandedMenu = '';
      }
      if (!item.children || !item.children.length) {
        this.router.navigate([item.route]);
        if (!this.depth) {
          this.commonService.expandedMenu = '';
        }
      }
      if (item.children && item.children.length) {
        this.commonService.expandedMenu = item.menuName;
      }
    }
  }

  getActiveMenu(item) {
    /* get theactive menu (main menu or side menu) */
    if (item.children && item.children.length) {
      if (item.children.filter(e => e.menuName === this.activeMenu).length > 0) {
        return true;
      }
    }
    if (item) {
      if (item.menuName == this.activeMenu) {
        return true;
      }
    }
    return false;
  }

  onChangeRoute(e: Event) {
    e.preventDefault();
  }
}
