import { trigger, transition, style, animate, query, stagger, animateChild, state, keyframes } from "@angular/animations";

export const listAnimations = [
    trigger('items', [
        transition(':enter', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('0.7s cubic-bezier(.8, -0.6, 0.26, 1.6)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('0.6s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ]),
    trigger('list', [
        transition(':enter', [
            query('@items', stagger(150, animateChild()), { optional: true })
        ]),
        transition(':leave', [
            query('@items', stagger(50, animateChild()), { optional: true })
        ]),
    ])
]

export const headreAnimations = [
    trigger('items', [
        transition('* => *', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('0.7s cubic-bezier(.8, -0.6, 0.26, 1.6)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ])

    ]),
    trigger('list', [
        transition('* => *', [
            query('@items', stagger(150, animateChild()))
        ]),

    ])
]

export const menuAnimations = [
    trigger('items', [
        transition(':enter', [
            style({ transform: 'scale(0.5)', opacity: 0 }),  // initial
            animate('0.2s cubic-bezier(.8, -0.6, 0.26, 1.6)',
                style({ transform: 'scale(1)', opacity: 1 }))  // final
        ]),
        transition(':leave', [
            style({ transform: 'scale(1)', opacity: 1, height: '*' }),
            animate('0.2s cubic-bezier(.8, -0.6, 0.2, 1.5)',
                style({
                    transform: 'scale(0.5)', opacity: 0,
                    height: '0px', margin: '0px'
                }))
        ])
    ]),
    trigger('list', [
        transition(':enter', [
            query('@items', stagger(150, animateChild()))
        ]),
        transition(':leave', [
            query('@items', stagger(50, animateChild()))
        ]),
    ]),
    trigger('indicatorRotate', [
        state('collapsed', style({ transform: 'rotate(0deg)' })),
        state('expanded', style({ transform: 'rotate(180deg)' })),
        transition('expanded <=> collapsed',
            animate('225ms cubic-bezier(0.4,0.0,0.2,1)')
        ),
    ])
]

export const gridAnimation = trigger('gridAnimation', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('.5s ease-in', keyframes([
        style({ opacity: 0, transform: 'scale(0.3) translate3d(0,0,0)', offset: 0 }),
        // style({ opacity: .9, transform: 'scale(1)', offset: 0.5 }),
        style({ opacity: 0.5, transform: 'scale(0.70)', offset: 0.5 }),
        style({ opacity: 1, transform: 'scale(1) translate3d(0,0,0)', offset: 1.0 })
      ]))
    ])
  ]
  );