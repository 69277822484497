import { Component, OnInit, ViewEncapsulation, HostListener } from '@angular/core';
import { CustomerCommonService } from '@shared/services/customer-common.service';
import { MenuItem, CustomerActiveMenu } from '@shared/interfaces/customer-menu';
import { Router } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { listAnimations, menuAnimations } from '@shared/animations/activity-log-animations';
import { AuthService } from '@shared/services/auth.service';
import { AdminDynamicHeaderService } from '@shared/services/admin-dynamic-header.service';
import { UserService } from '@shared/services/user.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-customer-menu',
  templateUrl: './customer-menu.component.html',
  styleUrls: ['./customer-menu.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [listAnimations, menuAnimations]
})
export class CustomerMenuComponent implements OnInit {
  private onDestroy$ = new Subject<boolean>();
  foldedMenu: boolean = true;
  activeMenu: CustomerActiveMenu;
  menuList: MenuItem[];
  innerWidth: any;
  innerScreenWidth: any;
  isSmallScreen: boolean = false;

  loggedUserName: string;
  userName: string;
  userImage: string;
  customerName: string;
  userId: number;
  isProfilePath: boolean = false;
  public url: any;

  constructor(
    public customerCommonService: CustomerCommonService,
    public router: Router,
    public dynamicHeader: AdminDynamicHeaderService,
    private userService: UserService,
    private authService: AuthService
  ) {}

  @HostListener('window:resize', ['$event']) onResize(event) {
    this.innerScreenWidth = window.innerWidth;
    this.getScreenWidth(this.innerScreenWidth); // on page resize
  }

  ngOnInit() {
    this.loggedUserName = localStorage.getItem('userName');
    this.userId = parseInt(localStorage.getItem('userId'));
    this.userName = localStorage.getItem('userName');
    if (localStorage.getItem('userImageName') == '') {
      this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
    } else {
      this.userImage = environment.S3StorageService?localStorage.getItem('S3BucketImagePath'): environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
    }
    this.dynamicHeader.userProperty.subscribe(UserImage => {
      this.loggedUserName = localStorage.getItem('userName');
      if (localStorage.getItem('userImageName') == '') {
        this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
      } else {
        this.userImage = environment.S3StorageService?localStorage.getItem('S3BucketImagePath'):environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
      }
    });
    this.getProfileDetails();

    this.innerWidth = window.innerWidth;
    this.getScreenSize(this.innerWidth); // to fold menu bar on small screen size
    this.getScreenWidth(this.innerWidth);

    this.customerCommonService.activeMenuSubject.pipe(takeUntil(this.onDestroy$)).subscribe(property => {
      this.activeMenu = property;
    });

    this.menuList = [
      {
        Icon: 'icon-customer-dash',
        RouterLink: '/app/customer/',
        MenuName: 'dashboard',
        DisplayName: 'Dashboard'
      },
      // {
      //   Icon: 'icon-customer-contract',
      //   RouterLink: '/app/customer/contracts',
      //   MenuName: 'contracts',
      //   DisplayName: 'Contracts'
      // },
      {
        Icon: 'icon-user-management',
        RouterLink: '/app/customer/users',
        MenuName: 'userManagement',
        DisplayName: 'User Management'
      },
      {
        Icon: 'icon-customer-profile',
        RouterLink: '/app/customer/profile',
        MenuName: 'customerProfile',
        DisplayName: 'Customer Profile'
      },
      {
        Icon: 'icon-customer-docs',
        RouterLink: '/app/customer/forms',
        MenuName: 'forms',
        DisplayName: 'Forms and Documents'
      },
      {
        Icon: 'icon-customer-request',
        RouterLink: '/app/customer/request',
        MenuName: 'requests',
        DisplayName: 'Requests'
      },
      {
        Icon: 'icon-customer-faq',
        RouterLink: '/app/customer/faqs',
        MenuName: 'faqs',
        DisplayName: 'FAQs'
      },
      {
        Icon: 'icon-additional-financing',
        RouterLink: '/app/customer/financing',
        MenuName: 'additionalFinancing',
        DisplayName: 'Additional Financing'
      }
    ];

    this.url = window.location.href;
    if (this.url.match(/myprofile/)) {
      this.isProfilePath = true;
    } else {
      this.isProfilePath = false;
    }
  }

  getScreenWidth(innerWidth) {
    innerWidth < 991 ? (this.isSmallScreen = true) : (this.isSmallScreen = false); //to hide toggle button
  }

  getScreenSize(innerWidth) {
    innerWidth < 990 ? (this.foldedMenu = true) : (this.foldedMenu = false);
    this.customerCommonService.foldedMenu.next(this.foldedMenu);
  }

  toggleMenu() {
    this.foldedMenu = !this.foldedMenu;
    this.customerCommonService.foldedMenu.next(this.foldedMenu);
  }

  navigateMenu(item: MenuItem) {
    this.isProfilePath = false;
    if (item.RouterLink) {
      this.router.navigate([item.RouterLink]);
    }
  }

  isMenuActive(MenuName: string) {
    return MenuName === (typeof this.activeMenu != 'undefined' ? this.activeMenu.ActiveMenu : '');
  }
  getProfileDetails() {
    this.loggedUserName = localStorage.getItem('userName');
    if (localStorage.getItem('userImageName') == '') {
      this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
    } else {
      this.userImage = environment.S3StorageService?localStorage.getItem('S3BucketImagePath'):environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
    }
  }

  onMenuChange(event) {
    switch (event) {
      case 'account':
        this.isProfilePath = true;
        this.router.navigateByUrl('/app/customer/myprofile');
        break;
      case 'logout':
        localStorage.clear();
        localStorage.clear();
        this.router.navigateByUrl('/app/login');
        this.authService.logout().subscribe(
          result => {
                $('<script type="text/javascript">SI_API.hideTab();</script>').appendTo("head");
          },
          error => {
            console.log('Error while logging out.', error);
          }
        );

        break;
    }
  }
  onChangeRoute(e: Event) {
    e.preventDefault();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
