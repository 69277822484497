import { AbpModule } from '@abp/abp.module';
import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import {
  DateAdapter, MatAutocompleteModule, MatButtonModule, MatCardModule, MatCheckboxModule, MatDatepickerModule, MatDialogModule, MatExpansionModule, MatFormFieldModule, MatIconModule, MatInputModule, MatListModule, MatMenuModule, MatNativeDateModule, MatPaginatorModule, MatRadioModule,
  MatRippleModule, MatSelectModule, MatSidenavModule, MatSliderModule, MatSortModule, MatTableModule, MatTabsModule, MatTooltipModule, MAT_DATE_FORMATS
} from '@angular/material';
import { RouterModule } from '@angular/router';
//import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { AppDateAdapter, APP_DATE_FORMATS } from '@shared/common/custom-date-format';
import { AuthService } from '@shared/services/auth.service';
import { DashboardService } from '@shared/services/dashboard.service';
import { EmailService } from '@shared/services/email.service';
import { LoginService } from '@shared/services/login.service';
import { PermissionService } from '@shared/services/permission.service';
import { TransactionService } from '@shared/services/transaction.service';
import { UserService } from '@shared/services/user.service';
import { TextMaskModule } from 'angular2-text-mask';
import { CookieService } from 'ngx-cookie-service';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppAuthService } from './auth/app-auth.service';
import { IsdirtyAlertboxComponent } from './common/isdirty-alertbox/isdirty-alertbox.component';
import { RestrictInputDirective } from './directives/input-value-restrict';
import { AdminFooterComponent } from './layout/admin-footer/admin-footer.component';
import { AdminHeaderComponent } from './layout/admin-header/admin-header.component';
import { AdminMenuComponent } from './layout/admin-menu/admin-menu.component';
import { MenuListItemComponent } from './layout/admin-menu/menu-list-item/menu-list-item.component';
import { NavService } from './layout/admin-menu/nav.service';
import { AlertsComponent } from './layout/alerts/alerts.component';
import { ChangePasswordComponent } from './layout/change-password/change-password.component';
import { CustomerHeaderComponent } from './layout/customer-header/customer-header.component';
import { CustomerMenuComponent } from './layout/customer-menu/customer-menu.component';
import { ProfileEditComponent } from './layout/profile-edit/profile-edit.component';
import { CommonProfileComponent } from './layout/profile/profile.component';
import { AppUrlService } from './nav/app-url.service';
import { customDatePipe } from './pipes/custom-date.pipe';
import { CustomerFilterPipe } from './pipes/customer-filter.pipe';
import { EllipsisPipe } from './pipes/elipsis.pipe';
import { PhonePipe } from './pipes/phone-format.pipe';
import { TruncateZero } from './pipes/truncate-zero';
import { UserFilterPipe } from './pipes/user-filter.pipe';
import { AppSessionService } from './session/app-session.service';


@NgModule({
  imports: [
    CommonModule,
    AbpModule,
    RouterModule,
    MatTooltipModule,
    FormsModule,
    MatMenuModule,
    ReactiveFormsModule,
    MatExpansionModule,
    MatButtonModule,
    MatListModule,
    MatSidenavModule,
    MatIconModule,
    MatRippleModule,
    TextMaskModule,
    MatCheckboxModule
  ],
  declarations: [
    AdminMenuComponent,
    AdminFooterComponent,
    AdminHeaderComponent,
    CustomerFilterPipe,
    UserFilterPipe,
    EllipsisPipe,
    customDatePipe,
    AlertsComponent,
    CommonProfileComponent,
    ProfileEditComponent,
    ChangePasswordComponent,
    PhonePipe,
    TruncateZero,
    RestrictInputDirective,
    MenuListItemComponent,
    CustomerHeaderComponent,
    CustomerMenuComponent,
    IsdirtyAlertboxComponent
  ],
  exports: [
    AdminMenuComponent,
    AdminFooterComponent,
    AdminHeaderComponent,
    MatPaginatorModule,
    MatTableModule,
    MatTooltipModule,
    MatIconModule,
    MatMenuModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    CustomerFilterPipe,
    MatSelectModule,
    MatButtonModule,
    UserFilterPipe,
    EllipsisPipe,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatDialogModule,
    MatTabsModule,
    RouterModule,
    customDatePipe,
    PhonePipe,
    TruncateZero,
    AlertsComponent,
    MatExpansionModule,
    NgxSpinnerModule,
    CommonProfileComponent,
    MatListModule,
    RestrictInputDirective,
    MatCheckboxModule,
    MatRadioModule,
    MatRippleModule,
    NgxMatSelectSearchModule,
    CustomerHeaderComponent,
    CustomerMenuComponent,
    MatSliderModule,
    MatCardModule
  ],
  providers: [ NavService, AuthService, CookieService, PermissionService, UserService, DashboardService, TransactionService,
    {provide: DateAdapter, useClass: AppDateAdapter},
    {provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS}
  ],
  entryComponents: [AlertsComponent]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [AppSessionService, AppUrlService, AppAuthService, LoginService, EmailService]
    };
  }
}
