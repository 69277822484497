import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

import { LoginService } from './login.service';
import { RestService } from './rest.service';


@Injectable()
export class DashboardService extends RestService {
    cutsomerId: BehaviorSubject<number> = new BehaviorSubject(0);
    isCustomerEdited: BehaviorSubject<null> = new BehaviorSubject(null);
    emitCustomerCount = new EventEmitter<any>();
    constructor(_http: HttpClient, loginService: LoginService) {
        super(_http, loginService);
    }

    getDashboardDetails() {
        return this.GET('Dashboard/GetDashboardDetails');
     }
     getAdminInvoice()
     {
        return this.GET('Dashboard/GetAdminInvoice');
     }
     getTopCustomer()
     {
        return this.GET('Dashboard/GetTopCustomers');
     }
     getRequests()
     {
        return this.GET('Dashboard/GetRequests');
     }
     getCustomerLogin()
     {
        return this.GET('Dashboard/GetCustomerLogin'); 
     }
     getDashboardHeader() {
        return this.GET('Dashboard/DashBoardHeader');
     }
     
     getAllInvoiceAmount(params: HttpParams) {
        return this.GET('Dashboard/GetAllAdminInvoice', params);
     }
     
     getAllRequests(params: HttpParams) {
        return this.GET('Dashboard/GetAllRequests', params);
     }
     
     getPaymentGraph() {
        return this.GET('Dashboard/GetPaymentGraph');
     }

     getCustomerInvoice()
     {
        return this.GET('Dashboard/GetCustomerInvoiceAmount');
     }
   
    
    getAllLoggedCustomers(params: HttpParams) {
        return this.GET('Dashboard/GetAllLoggedCustomers', params);
    }

    getCustomerContrats() {
       
        return this.GET('Dashboard/GetCustomerContracts');
    }
    getPendingPaymentAccounts()
    {
        return this.GET('Payment/GetPendingPaymentAccounts');
    }

    getACHAccountsToVerify() {
       
      return this.GET('Payment/GetMicrotransferVerificationPendingACHAccounts');
  }

    getTopCustomersViewAll(params: HttpParams) {
        return this.GET('Dashboard/GetTopCustomersViewAll', params);
    }

    getCreditACHViewAll(params: HttpParams) {
        return this.GET('Dashboard/GetCreditACHViewAll', params);
    }
   
    
}
