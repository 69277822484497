import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CustomerActiveMenu } from '@shared/interfaces/customer-menu';

@Injectable({
  providedIn: 'root'
})
export class CustomerCommonService {
  foldedMenu: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  activeMenu: CustomerActiveMenu = {
    ActiveMenu: ''
  };
  activeMenuSubject: BehaviorSubject<CustomerActiveMenu> = new BehaviorSubject<CustomerActiveMenu>(this.activeMenu);
 
  private amountSource = new BehaviorSubject<number>(0);
  currentFinanceAmount = this.amountSource.asObservable();

  constructor() {}

  changeMessage(amount: number) {
    this.amountSource.next(amount)
  }

  setActiveMenu(menu: CustomerActiveMenu) {
    this.activeMenu = menu;
    this.activeMenuSubject.next(this.activeMenu);
    // console.log('set active', this.activeMenu);
  }
  numberWithCommas(x) {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else return x;
  }
  formatDate(date) {
    var d = date,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;
    return [month, day, year].join('/');
  }

  processDate(date) {
    var parts = date.split('/');
    return new Date(parts[2], parts[0] - 1, parts[1]);
  }
}
