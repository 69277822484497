import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'customDate',
  pure: true
})

export class customDatePipe extends DatePipe {
    transform(value: any, pattern: string = "mediumDate"): string|null {
        let result = super.transform(value, pattern);
        result += " " + this.map[Intl.DateTimeFormat().resolvedOptions().timeZone];
        return result;
    }
    map = {
        "Asia/Calcutta": "IST"
    };
}


export class Constants {
    static readonly DATE_FMT = 'dd/MMM/yyyy';
    static readonly DATE_TIME_FMT = `${Constants.DATE_FMT} hh:mm:ss`;
  }