import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-box',
  templateUrl: './alertbox.component.html'
})
export class AlertBoxComponent {
  constructor(
    public router: Router,
    public dialogRef: MatDialogRef<AlertBoxComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
  }

  onCancelClick() {
    this.dialogRef.close();
  }
  onProceed() {
    this.dialogRef.close();
    this.router.navigateByUrl('/app/customer/contracts/pay-now/12');
  }
}

/* Opening dialogue box
 =======================
   let ref = this.dialog.open(AlertBoxComponent, {
      width: '520px',
      height: 'auto',
      data: { isOpened: [true/false],
              header: [alert box heading],
              content: [alert box text]
            }
    });
*/
