import { Component, HostListener, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material';
import { DialogService } from '@shared/services/dialog.service';

/**
 * ModalComponent - This class represents the modal component.
 * @requires Component
 */

@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  public dialogTitle: string;
  public dialogBody: string;

  constructor(public dialogRef: MatDialogRef<ConfirmDialogComponent>) {}
}
