import { Injectable } from '@angular/core';
import { exists } from 'fs';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  expandedMenu: string;

  constructor() {}

  numberWithCommas(x) {
    if (x) return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    else return x;
  }

  formatDate(date) {
    var d = date,
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [month, day, year].join('/');
  }

  processDate(date) {
    var parts = date.split('/');
    return new Date(parts[2], parts[0] - 1, parts[1]);
  }

  getPaginationLength(module) {
    var pagination = [];
    switch (module) {
      case 'user':
        pagination = [10, 20, 50];
        break;
      case 'user-contract':
        pagination = [10, 20, 50];
        break;
      case 'user-contract-grid':
        pagination = [8, 16, 24];
        break;
      case 'user-forms':
        pagination = [10, 20, 50];
        break;
      case 'customer':
        pagination = [10, 20, 50];
        break;
      case 'request':
        pagination = [10, 20, 50];
        break;
      case 'finance':
        pagination = [10, 20, 50];
        break;
      case 'calculator':
        pagination = [10, 20, 50];
        break;
      case 'banner':
        pagination = [10, 20, 50];
        break;
      case 'form':
        pagination = [10, 20, 50];
        break;
      case 'faq':
        pagination = [10, 20, 50];
        break;
      case 'customer_grid':
        pagination = [12, 24, 48];
        break;
      case 'user_grid':
        pagination = [12, 24, 48];
        break;
      case 'report':
        pagination = [10, 20, 50];
        break;
      case 'customer_details_users':
        pagination = [5, 10, 15];
        break;
      case 'customer_details':
        pagination = [10, 20, 50];
        break;
      default:
        pagination = [10, 20, 50];
        break;
    }
    return pagination;
  }
  numberOnly(event: any, flag: string) {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  public noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }
  omit_special_char(event) {
    var k;
    k = event.charCode; //         k = event.keyCode;  (Both can be used)
    return (k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57);
  }
  utcify(date: Date): Date {
    if (date) {
      return new Date(date.toUTCString());
    } else {
      return new Date();
    }
  }

  convertUTCDateToLocalDate(date: Date) {
    if (date) {
      var newDate = new Date(date);
     // var offset = newDate.getTimezoneOffset() / 60;
      //var offsetTime = new Date(newDate.getTime() - offset * 60 * 1000 * 60);
      return newDate;
    }
  }
}
