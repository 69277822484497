import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { ConfirmDialogComponent } from '@shared/common/confirm-dialog/confirm-dialog.component';
import { MatDialog, MatDialogRef } from '@angular/material';

/**
 * ModalService - Service used to interact with the Modal Component
 */
@Injectable()
export class DialogService {
  constructor(private dialog: MatDialog) {}
  public confirm(title: string, body: string): Observable<boolean> {
    let dialogRef: MatDialogRef<ConfirmDialogComponent>;

    dialogRef = this.dialog.open(ConfirmDialogComponent);
    dialogRef.componentInstance.dialogTitle = title;
    dialogRef.componentInstance.dialogBody = body;

    return dialogRef.afterClosed();
  }
}
