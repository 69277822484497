import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';
import { LoginService } from './login.service';
import { RestService } from './rest.service';
import { UserDto } from '@shared/interfaces/nec-user';
import { EmailTemplate } from '@shared/interfaces/email';


@Injectable()
export class EmailService extends RestService {
    cutsomerId: BehaviorSubject<number> = new BehaviorSubject(0);
    emitCustomerCount = new EventEmitter<any>();
    constructor(_http: HttpClient, loginService: LoginService) {
        super(_http, loginService);
    }


    getEmailTemplates(params: HttpParams) {
        return this.GET('EmailTemplate/GetEmailTemplates', params);
    }

    getEmailDetails(id: number) {
        return this.GET('EmailTemplate/Get?id=' + id);
    }






    changeCustomerStatus(customerId: number) {
        return this.PUT('Customer/Deactivate?customerId=' + customerId, '');
    }

    getCustomerDetails(id: number) {
        return this.GET('Customer/Get?id=' + id);
    }
    getCustomerContracts(params: HttpParams) {
        return this.GET('Customer/GetCustomerContract', params);
    }
    ChangeUserStatus(userId: number) {
        return this.PUT('User/ChangeUserStatus?userId=' + userId, '');
    }
    ChangeCustomerUserStatus(userId: number) {
        return this.PUT('User/ChangeCustomerUserStatus?userId=' + userId, '');
    }

    deleteUsers(userId: number) {
        return this.DELETE('User/Delete?id=' + userId);
    }

    CustomerUserDelete(userId: number) {
        return this.DELETE('User/CustomerUserDelete?id=' + userId);
    }
    getCustomerCount() {
        var newDate = new Date();
        var offset = newDate.getTimezoneOffset();
        return this.GET('Customer/GetCustomersCount?timeZone=' + offset);
    }
    UpdateCustomerUser(userDetails: UserDto) {
        return this.PUT('User/UpdateCustomerUser', userDetails);
    }
    getContractCount(cutsomerId: number) {
        return this.GET('Customer/GetCustomerContractsCount?customerId=' + cutsomerId);
    }
    UpdateCustomer(custDetails: any) {
        return this.PUT('Customer/Update', custDetails);
    }
    SendLoginDetails(userId: any) {
        return this.PUT('User/SendLoginDetails?userId=' + userId, '');
    }
    getCustomerProfileDetails(customerId: number) {
        return this.GET('User/SendLoginDetails?userId=' + customerId);
    }

    getRequestFiledType() {
        return this.GET('Customer/GetCustomerEditFields');
    }

    getUserCustomerDetails(id: number) {
        return this.GET('Customer/GetCutomerDetails?id=' + id);
    }

    updateCustomerUser(userDetails: any) {
        return this.PUT('Customer/Update', userDetails);
    }

    getEmailTemplateSmartTags(id: number) {
        return this.GET('EmailTemplate/GetEmailSmartTags?id=' + id);
    }

    updateEmailTemplate(emailTemplate: EmailTemplate) {
        return this.PUT('EmailTemplate/Update', emailTemplate);
    }
}
