import {
  trigger,
  animate,
  transition,
  style,
  group,
  stagger,
  keyframes,
  query,
  state,
  animateChild,
  sequence
} from '@angular/animations';

export const fadeAnimation = trigger('fadeAnimation', [
  // The '* => *' will trigger the animation to change between any two states
  // transition('* <=> *', [
  //   /* order */
  //   query(':enter, :leave', style({ position: 'fixed', width: '100%', height: '100%' }), { optional: true }),

  //   query(':enter', [style({ opacity: 0 })], { optional: true }),
  //   query(':leave', [style({ opacity: 1 }), animate('0.4s ease-in-out', style({ opacity: 0 }))], { optional: true }),
  //   query(':enter', [style({ opacity: 0 }), animate('0.4s 0.8s ease-in-out', style({ opacity: 1 }))], { optional: true }),

  // ])

  transition(
    '* => *',
    group([
      query(
        ':enter, :leave ',
        [
          style({
            position: 'fixed',
            width: '100%',
            height: '100%'
          })
        ],
        { optional: true }
      ),

      query(':enter', [style({ opacity: 0, visibility: 'hidden', pointerEvents: 'none' })], { optional: true }),
      query(
        ':leave',
        [
          style({
            opacity: 1,
            visibility: 'visible'
          }),
          animate(
            '0.6s cubic-bezier(0.0, 0.0, 0.2, 1)',
            style({
              opacity: 0,
              visibility: 'hidden'
            })
          )
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            opacity: 0,
            visibility: 'hidden'
          }),
          animate(
            '1.4s 0.6s cubic-bezier(0.0, 0.0, 0.2, 1)',
            style({
              opacity: 1,
              visibility: 'visible'
            })
          )
        ],
        { optional: true }
      ),
      query(':enter', animateChild(), { optional: true }),
      query(':leave', animateChild(), { optional: true })
    ])
  )

  //   transition('* => *', [
  //     query(':enter, :leave', [
  //         style({
  //             position: 'absolute',
  //             top     : 0,
  //             bottom  : 0,
  //             left    : 0,
  //             right   : 0
  //         })
  //     ], {optional: true}),
  //     query(':enter', [
  //         style({
  //             transform: 'translateY(-100%)',
  //             opacity  : 0
  //         })
  //     ], {optional: true}),
  //     sequence([
  //         group([
  //             query(':leave', [
  //                 style({
  //                     transform: 'translateY(0)',
  //                     opacity  : 1
  //                 }),
  //                 animate('0.6s cubic-bezier(0.0, 0.0, 0.2, 1)',
  //                     style({
  //                         transform: 'translateY(100%)',
  //                         opacity  : 0
  //                     }))
  //             ], {optional: true}),
  //             query(':enter', [
  //                 style({transform: 'translateY(-100%)'}),
  //                 animate('0.6s 0.8s cubic-bezier(0.0, 0.0, 0.2, 1)',
  //                     style({
  //                         transform: 'translateY(0%)',
  //                         opacity  : 1
  //                     }))
  //             ], {optional: true})
  //         ]),
  //         query(':leave', animateChild(), {optional: true}),
  //         query(':enter', animateChild(), {optional: true})
  //     ])
  // ])
]);

export const fadeAnimationIE = trigger('fadeAnimationIE', [
  transition(
    '* => *',
    group([
      query(
        ':enter, :leave ',
        [
          style({
            position: 'fixed',
            width: '100%',
            height: '100%'
          })
        ],
        { optional: true }
      ),

      query(':enter', [style({ opacity: 0, visibility: 'hidden', position: 'relative' })], { optional: true }),
      query(
        ':leave',
        [
          style({
            opacity: 1,
            visibility: 'visible'
          }),
          animate(
            '0.6s cubic-bezier(0.0, 0.0, 0.2, 1)',
            style({
              opacity: 0,
              visibility: 'hidden'
            })
          )
        ],
        { optional: true }
      ),
      query(
        ':enter',
        [
          style({
            opacity: 0,
            visibility: 'hidden'
          }),
          animate(
            '1.4s 0.6s cubic-bezier(0.0, 0.0, 0.2, 1)',
            style({
              opacity: 1,
              visibility: 'visible'
            })
          )
        ],
        { optional: true }
      ),
      query(':enter', animateChild(), { optional: true }),
      query(':leave', animateChild(), { optional: true })
    ])
  )
]);

export const enterAnimation = trigger('enterAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.4s', style({ opacity: 1 })),
    animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.0s', style({ opacity: 0 })),
    animate('0ms', style({ transform: 'translateX(100%)', opacity: 0 }))
  ])
]);

export const entryleaveAnimation = trigger('entryleaveAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.4s', style({ opacity: 1 })),
    animate('500ms', style({ transform: 'translateX(0)', opacity: 1 }))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.4s', style({ opacity: 0 })),
    animate('500ms', style({ transform: 'translateX(100%)', opacity: 0 }))
  ])
]);

export const childAnimation = trigger('childAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      '.6s ease-in',
      keyframes([
        style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
        style({ opacity: 0.5, transform: 'translateY(35px)', offset: 0.3 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
      ])
    )
  ])
]);

export const shakeAnimation = trigger('shakeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      '1.0s ease-in',
      keyframes([
        style({ opacity: 0, transform: 'translateY(0%)', offset: 0 }),
        style({ opacity: 0.5, transform: 'translateY(15px)', offset: 0.3 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
      ])
    )
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate(
      '1.0s ease-out',
      keyframes([
        style({ opacity: 1, transform: 'translateY(15px)', offset: 0 }),
        style({ opacity: 0.5, transform: 'translateY(0)', offset: 0.3 }),
        style({ opacity: 0, transform: 'translateY(-20%)', offset: 1.0 })
      ])
    )
  ])
]);

export const loginAnimation = trigger('loginAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      '0.2s ease-in',
      keyframes([
        // style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
        // style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
      ])
    )
  ])
]);
export const rowAnimation = trigger('rowAnimation', [
  transition(':enter', [
    style({ height: '*', opacity: '0', transform: 'translateX(-550px)', 'box-shadow': 'none' }),
    sequence([
      animate('0.45s ease', style({ height: '*', opacity: '.2', transform: 'translateX(0)', 'box-shadow': 'none' })),
      animate('0.45s ease', style({ height: '*', opacity: 1, transform: 'translateX(0)' }))
    ])
  ]),
  transition(':leave', [
    style({ height: '*', opacity: '1', transform: 'translateX(0)', 'box-shadow': 'none' }),
    sequence([
      animate('0.35s ease', style({ height: '*', opacity: '.5', transform: 'translateX(0)', 'box-shadow': 'none' })),
      // animate("0.45s ease", style({ height: '*', opacity: 0, transform: 'translateX(550px)' }))
      animate('0.45s ease', style({ height: '*', opacity: 0 }))
    ])
  ])
]);

export const columnAnimation = trigger('columnAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate(
      '0.8s ease-in',
      keyframes([
        // style({ opacity: 0, transform: 'translateY(-75%)', offset: 0 }),
        // style({ opacity: .5, transform: 'translateY(35px)', offset: 0.3 }),
        style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })
      ])
    )
  ])
]);

export const easeAnimation = trigger('easeAnimation', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.5s ease-in', keyframes([style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })]))
  ]),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('.2s ease-out', keyframes([style({ opacity: 0, transform: 'translateY(0)', offset: 1.0 })]))
  ])
]);

export const logincardAnimation = trigger('logincardAnimation', [
  state(
    'login',
    style({
      transform: 'rotateY(0)'
    })
  ),
  state(
    'forgotPassword',
    style({
      transform: 'rotateY(180deg)'
    })
  ),
  state(
    'signupCustomer',
    style({
      transform: 'rotateY(180deg)'
    })
  ),
  transition('login => forgotPassword', animate('500ms ease-out')),
  transition('forgotPassword => login', animate('500ms ease-in')),
  transition('login => signupCustomer', animate('500ms ease-out')),
  transition('signupCustomer => login', animate('500ms ease-in'))
]);

export const easeAnimationGrid = trigger('easeAnimationGrid', [
  transition(':enter', [
    style({ opacity: 0 }),
    animate('.6s ease-in', keyframes([style({ opacity: 1, transform: 'translateY(0)', offset: 1.0 })]))
  ])
  // transition(':leave', [
  //   style({ opacity: 1 }),
  //   animate('.0s ease-out', keyframes([
  //     style({ opacity: 0, transform: 'translateY(0)', offset: 1.0 }),
  //   ]))
  // ])
]);
