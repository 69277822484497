import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { UserService } from '@shared/services/user.service';

@Component({
  selector: 'app-isdirty-alertbox',
  templateUrl: './isdirty-alertbox.component.html',
  styleUrls: ['./isdirty-alertbox.component.css']
})
export class IsdirtyAlertboxComponent implements OnInit {
  @Input() isDirty;

  modalFlag: boolean = false;
  constructor(private userService: UserService) {}

  ngOnInit() {
    this.modalFlag = this.isDirty;
  }
  onCancelClick() {
    this.modalFlag = false;
  }

  onConfirmationClick() {
    this.modalFlag = false;
    this.userService.IsDirtyClear.next(null);
  }
  ngOnChanges(changes: SimpleChanges): void {
    var isDirty = changes['isDirty'].currentValue;
    if (isDirty) this.modalFlag = true;
    else this.modalFlag = true;
  }
}
