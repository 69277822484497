import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AdminHeaderProperties } from '@shared/interfaces/AdminHeader';

@Injectable({
  providedIn: 'root'
})
export class AdminDynamicHeaderService {
  defaultValues: AdminHeaderProperties = {
    title: 'Admin',
    breadcrumbs: [],
    iconPath: 'icon-users'
  };
  userPermission: any;
  userPermissions: any;

  /* breadcrumbs array */
  properties: BehaviorSubject<AdminHeaderProperties> = new BehaviorSubject<AdminHeaderProperties>(this.defaultValues);
  userProperty: BehaviorSubject<AdminHeaderProperties> = new BehaviorSubject<AdminHeaderProperties>(null);
  permissions: BehaviorSubject<any> = new BehaviorSubject<any>(this.userPermissions);
  permissionsWhenPageReload: BehaviorSubject<any> = new BehaviorSubject<any>(this.userPermission);
  constructor() {}

  setproperties(object: AdminHeaderProperties) {
    this.properties.next(object);
  }
  setUserDetails() {
    this.userProperty.next(null);
  }
  setUserPermission(object: any) {
    this.permissions.next(object);
  }
  setUserPermissionWhenPageReload(object: any) {
    this.permissionsWhenPageReload.next(object);
  }
}
