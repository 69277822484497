import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phone'
})
export class PhonePipe {
    transform(val) {
        if (val != null&& val !='-') {
            let newStr = '';
            if (val.length <= 10) {
                newStr = '(' + val.substr(0, 3) + ') ' + val.substr(3, 3) + ' - ' + val.substr(6);
            } else {
                newStr = val;
            }
            return newStr 
        }
        else
        {
            return '-'
        }
        //(999) 999 - 9999;      
        
    }
}
