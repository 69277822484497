import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(
    private http: HttpClient
  ) { }

  public get(endPoint): Observable<any> {
    let data = this.http.get(endPoint);
    return data;
  }

  getAsPromise(url) {
    return new Promise((resolve, reject) => {
      this.http.get(url).subscribe((responce: any) => {
        if (responce) {
          resolve({ 'status': true, 'data': responce.data });
        } else {
          reject({ 'status': false, 'data': responce })
        }
      },
        function (err) {
          this.handleError(err);
          reject({ 'status': false });
        })
    });
  }

  getAsObservable(endpoint: string): Observable<any[]> {
    let responce: any;
    return new Observable(observer => {
      this.http.get(endpoint).subscribe(data => {
        responce = data;
        observer.next(responce.data);
        observer.complete();
      },
        err => {
          observer.error(err.message);
          observer.complete();
        })
    })
  }

  post(data, url) {
    return this.http.post(url,this.convertToFormData(data));
  }

  convertToFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach(key => formData.append(key, object[key]));
    return formData;
  }


  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  };
}
