import { Component, OnInit, ViewEncapsulation, AfterViewInit } from '@angular/core';
import { AdminDynamicHeaderService } from '@shared/services/admin-dynamic-header.service';
import { AdminHeaderProperties } from '@shared/interfaces/AdminHeader';
import { Subscription } from 'rxjs/internal/Subscription';
import { headreAnimations, menuAnimations } from '@shared/animations/activity-log-animations';
import { trigger, transition, style, animate } from '@angular/animations';
import { Router, NavigationStart } from '@angular/router';
import { environment } from 'environments/environment';
import { AuthService } from '@shared/services/auth.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [headreAnimations, menuAnimations]
})
export class AdminHeaderComponent implements OnInit, AfterViewInit {
  title: string = '';
  breadCrumbs;
  iconPath: string;
  breabcrumbState: string;
  userName: string;
  expand_button: string = 'expand_more';
  //state: string = 'collapsed';
  userImage: string;
  permissions: any;
  currentTime: Date;

  private propertiesSubscription: Subscription;
  private routerSubscription: Subscription;
  private UserImageSubscription: Subscription;

  constructor(
    private dynamicHeader: AdminDynamicHeaderService,
    public router: Router,
    private authService: AuthService
  ) {}

  ngOnInit() {
    /* subscribe to headre title */
    this.propertiesSubscription = this.dynamicHeader.properties.subscribe(updatedProperties => {
      this.title = updatedProperties.title;
      this.breadCrumbs = updatedProperties.breadcrumbs;
      this.iconPath = updatedProperties.iconPath;
      this.breabcrumbState = Math.random()
        .toString(36)
        .substring(7);
    });

    this.setUserImage();
    this.UserImageSubscription = this.dynamicHeader.userProperty.subscribe(UserImage => {
      this.setUserImage();
    });

    this.routerSubscription = this.router.events.subscribe(event => {
      /* to reset the menu icon on router change */
      if (event instanceof NavigationStart) {
        //this.state = 'collapsed';
      }
    });
  }
  ngAfterViewInit() {
    setInterval(() => {
      //replaced function() by ()=>
      this.currentTime = new Date();
    }, 500);
  }
  onMenuChange(event) {
    /* to reset the menu icon on menu click */

    //this.state = 'collapsed';
    switch (event) {
      case 'account':
        this.router.navigateByUrl('/app/admin/profile');
        break;
      case 'logout':
        localStorage.clear();
        this.router.navigateByUrl('/app/login');
        this.authService.logout().subscribe(
          result => {
                    },
          error => {
            console.log('Error while logging out.', error);
          }
        );
        break;
    }
  }
  setUserImage() {
    this.userName = localStorage.getItem('userName');
    if (localStorage.getItem('userImageName') == '') {
      this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
    } else {
      this.userImage = environment.S3StorageService?localStorage.getItem("S3BucketImagePath"): environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
    }
  }
  ngOnDestroy(): void {
    // this.propertiesSubscription.unsubscribe();
    // this.routerSubscription.unsubscribe();
  }
  // profileMenu() {
  //   /* to swap the menu icon on profile icon click */
  //   this.state = (this.state == 'expanded' ? 'collapsed' : 'expanded');
  // }
}
