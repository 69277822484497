import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, NavigationEnd } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subscription, merge } from 'rxjs';
import { AdminDynamicHeaderService } from '@shared/services/admin-dynamic-header.service';
import { environment } from 'environments/environment';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class ParentAuthGuard implements CanActivate {
    currentUrl: any;
    userPermission: any[] = []
    constructor(
        private router: Router,
        public http: HttpClient
    ) { }
    ngOnInit() {
    }
    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (localStorage.getItem('access_token') !== null) {
            let loginDuration = this.getLoginMinutes(new Date(localStorage.getItem('login_time')), new Date());
            let expiryDuration = environment.timeOutDuration * 24 * 60;
            if (loginDuration < expiryDuration) {
                let isCustomer = (localStorage.getItem('userRole') || '').toLowerCase().includes('customer');
                let isAdminUrl = state.url.includes('admin');
                if (isCustomer && !isAdminUrl) {
                    return true;
                } else if (!isCustomer && isAdminUrl) {
                    return true;
                } else {
                    this.router.navigate(['/unauthorized']);
                }                
            }
        } else {
            this.router.navigate(['']);
        }
        let loggedInUser = localStorage.getItem('loggedInUser'); // For rememberme autofill
        let loggedEmail = localStorage.getItem('email'); // For rememberme autofill
        localStorage.clear();
        localStorage.setItem("loggedInUser", loggedInUser);
        localStorage.setItem("email", loggedEmail);
        this.router.navigate(['app/login']);
        return false;
    }

    getLoginMinutes(logTime: Date, curTime: Date) {
        var totalMinutes = 0;
        totalMinutes = (curTime.getTime() - logTime.getTime()) / (1000 * 60);
        return totalMinutes;
    }
}

@Injectable()
export class PageGuard implements CanActivate {
    currentUrl: any;
    userPermission: any[] = []
    constructor(
        private router: Router,
        public http: HttpClient,
        private cookieService: CookieService
    ) { }

    ngOnInit() { }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
            const UserLoggedInvalue: string = this.cookieService.get('isRemember');

            if (localStorage.getItem('access_token') !== null && UserLoggedInvalue) {
                let loginDuration = this.getLoginMinutes(new Date(localStorage.getItem('login_time')), new Date());
                let expiryDuration = environment.timeOutDuration * 24 * 60;
                if (loginDuration < expiryDuration) {
                    let isCustomer = (localStorage.getItem('userRole') || '').toLowerCase().includes('customer');
                    if (isCustomer) {
                        this.router.navigate(['app/customer']);
                    } else {
                        this.router.navigate(['app/admin']);                    
                    }
                    return false;
                }
            }
            localStorage.clear();
            // this.router.navigate(['app/login']);
            return true;
            
    }

    getLoginMinutes(logTime: Date, curTime: Date) {
        var totalMinutes = 0;
        totalMinutes = (curTime.getTime() - logTime.getTime()) / (1000 * 60);
        return totalMinutes;
    }
}






