import { Injectable } from '@angular/core';

import { TokenService } from '@abp/auth/token.service';

@Injectable()
export class PermissionService {

    constructor() { }

    public permission=[] as any[];
 
}
