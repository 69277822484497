import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FAQDto } from '@shared/interfaces/faq'
import { LoginService } from './login.service';
import { RestService } from './rest.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class FaqService extends RestService {
    private isFaqDeleted = new BehaviorSubject("default message");
    currentDeleteStatus = this.isFaqDeleted.asObservable();
    constructor(_http: HttpClient, loginService: LoginService) {
        super(_http, loginService);
    }

    changeDeleteStatus(message: string) {
        this.isFaqDeleted.next(message)
      }
    AddNewFaq(faqDetails: FAQDto) {
        return this.POST('FAQs/Create', faqDetails);
    }
    getFAQs(params: HttpParams) {
        return this.GET('FAQs/GetAllFAQByCategory', params);
    }
   deleteFaq(id:number)
   {
    return this.DELETE('FAQs/Delete?id=' + id);

   }
   
    getFaqCtegories() {
        return this.GET('FAQs/GetFAQCategories');
    }   
    UpdateFaq(faqDetails:FAQDto){
        return this.PUT('FAQs/Update', faqDetails);
    } 
}
