import { Component, OnInit, ViewEncapsulation, ViewChild, ElementRef, Renderer2, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { environment } from 'environments/environment';
import { AdminDynamicHeaderService } from '@shared/services/admin-dynamic-header.service';
import { UserService } from '@shared/services/user.service';
import { headreAnimations, menuAnimations } from '@shared/animations/activity-log-animations';
import { MatDialog } from '@angular/material';
import { AlertBoxComponent } from '@shared/common/alertbox/alertbox.component';

@Component({
  selector: 'app-customer-header',
  templateUrl: './customer-header.component.html',
  styleUrls: ['./customer-header.component.css'],
  encapsulation: ViewEncapsulation.None,
  animations: [headreAnimations, menuAnimations]
})
export class CustomerHeaderComponent implements OnInit {
  loggedUserName: string;
  userName: string;
  userImage: string;
  customerName: string;
  userId: number;
  // showNotification: string;

  // @ViewChild('toggleButton') toggleButton: ElementRef;
  // @ViewChild('divPanel') divPanel: ElementRef;

  constructor(
    public router: Router,
    private authService: AuthService,
    private userService: UserService,
    public dynamicHeader: AdminDynamicHeaderService,
    private renderer: Renderer2,
    public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.loggedUserName = localStorage.getItem('userName');
    this.userId = parseInt(localStorage.getItem('userId'));
    this.userName = localStorage.getItem('userName');

    // this.showNotification = localStorage.getItem('showNotification');

    if (localStorage.getItem('userImageName') == '') {
      this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
    } else {
      this.userImage = environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
    }

    this.dynamicHeader.userProperty.subscribe(UserImage => {
      this.loggedUserName = localStorage.getItem('userName');
      if (localStorage.getItem('userImageName') == '') {
        this.userImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
      } else {
        this.userImage = environment.baseUrl + 'Images/UserProfile/' + localStorage.getItem('userImageName');
      }
    });
    // this.renderer.listen('window', 'click', (e: Event) => {
    //   if (e.target !== this.toggleButton.nativeElement && e.target !== this.divPanel.nativeElement) {
    //     this.showNotification = false;
    //   }
    // });

    this.getProfileDetails();
  }

  // ngAfterViewInit() {
  //   if (this.showNotification == 'true') {
  //     setTimeout(() =>
  //       this.dialog.open(AlertBoxComponent, {
  //         width: '520px',
  //         height: 'auto',
  //         data: { AlertType: 'customer-notification-status' }
  //       })
  //     );
  //     this.showNotification = 'false';
  //     localStorage.removeItem(this.showNotification);
  //   }
  // }
  ellipsVal(val, args) {
    if (val) {
      if (args === undefined) {
        return val;
      }

      if (val.length > args) {
        return val.substring(0, args) + '...';
      } else {
        return val;
      }
    }
  }
  getProfileDetails() {
    this.userService.getUser(this.userId).subscribe(data => {
      if (data.result.status == 'success') {
        this.customerName = data.result.data.customerName;
        var imageName = '';
        if (data.result.data.document) {
          imageName = data.result.data.document.thumbImageFileName ? data.result.data.document.thumbImageFileName : '';
        }
        localStorage.setItem('userImageName', imageName);
        localStorage.setItem('userName', data.result.data.fullName);
        if (data.result.data.document) {
          localStorage.setItem('S3BucketImagePath',  data.result.data.document.url);
        }
      }
    }); 
  }

  onMenuChange(event) {
    switch (event) {
      case 'account':
        this.router.navigateByUrl('/app/customer/myprofile');
        break;
      case 'logout':
        localStorage.clear();
        localStorage.clear();
        this.router.navigateByUrl('');
     
        this.authService.logout().subscribe(
          result => {
          
            },
          error => {
            console.log('Error while logging out.', error);
          }
        );

        break;
    }
  }
  // selectNotifications() {
  //   this.showNotification = !this.showNotification;
  // }
  goToDetails() {
    let ref = this.dialog.open(AlertBoxComponent, {
      width: '520px',
      height: 'auto',
      data: { AlertType: 'paynow-details' }
    });
  }
}
