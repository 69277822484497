import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserDto, NecUserList, CustomerUserDto } from '@shared/interfaces/nec-user';
import { LoginService } from './login.service';
import { RestService } from './rest.service';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class TransactionService extends RestService {
  IsDirtyClear: BehaviorSubject<null> = new BehaviorSubject(null);
  constructor(_http: HttpClient, loginService: LoginService) {
    super(_http, loginService);
  }

  getAll() {
    return this.GET('TransactionFeeSetting/GetAll');
  }
  updateACH(transList: any) {
    return this.PUT('TransactionFeeSetting/Update', transList);
  }
  updateCC(transList: any) {
    return this.PUT('TransactionFeeSetting/Update', transList);
  }
  GetAllRoles() {
    return this.GET('Role/GetAll');
  }
  deleteUsers(userId: number) {
    return this.DELETE('User/Delete?id=' + userId);
  }

  ChangeUserStatus(userId: number) {
    return this.PUT('User/ChangeUserStatus?userId=' + userId, '');
  }

  GetAdminRoles() {
    return this.GET('Role/GetAdminRoles');
  }
  // AddAdminUser(userDetails: CustomerUserDto) {
  //   return this.POST('User/CreateCustomerUser', userDetails);
  // }

  UpdateUserByCustomer(userDetails: CustomerUserDto) {
    return this.PUT('User/UpdateCustomerUser', userDetails);
  }
  createUserByCustomer(userDetails: CustomerUserDto) {
    return this.POST('User/CreateUserByCustomer', userDetails);
  }
  UpdateCustomerUser(userDetails: CustomerUserDto) {
    return this.PUT('User/UpdateCustomerUser', userDetails);
  }
  getCustomerUsers(params: HttpParams) {
    return this.GET('User/GetCustomerUsers', params);
  }

  getUser(userId: number) {
    return this.GET('User/Get?Id=' + userId);
  }
  changePassword(userPasswordInput: any) {
    return this.PUT('User/ChangePassword', userPasswordInput);
  }
  resetPassWord(userid: string) {
    return this.GET('User/SendResetPasswordLink?userId=' + userid);
  }
  getCostTransactionFee(amount:number){
    return this.GET('TransactionFeeSetting/GetCostTransactionFee?amount='+amount);
  }
}
