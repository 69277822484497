import { Injectable } from '@angular/core';
import { AppConsts } from '@shared/AppConsts';

import { TokenService } from '@abp/auth/token.service';

@Injectable()
export class LoginService {

    constructor(
        private _tokenService: TokenService,
    ) {
    }

    public login(accessToken: string, encryptedAccessToken: string, expireInSeconds: number, rememberMe?: boolean): void {
        const tokenExpireDate = rememberMe ? (new Date(new Date().getTime() + 1000 * expireInSeconds)) : undefined;

        this._tokenService.setToken(
            accessToken,
            tokenExpireDate
        );
    }
}
