import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customerFilter',
  pure: false
})
export class CustomerFilterPipe implements PipeTransform {
  transform(value: any, args?: any): any {
    if (!value || !args) {
        return value;
    }
    return value.filter(item => item.CompanyId == args);
  }
}
