import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  ViewChild,
  ViewEncapsulation
} from "@angular/core";
import { VERSION } from "@angular/material";
import { NavigationEnd, Router } from "@angular/router";
import { listAnimations } from "@shared/animations/activity-log-animations";
import { AdminDynamicHeaderService } from "@shared/services/admin-dynamic-header.service";
import { CommonService } from "@shared/services/common.service";
import { Subscription } from "rxjs";
import { NavItem } from "./nav-item";
import { NavService } from "./nav.service";
@Component({
  selector: "app-admin-menu",
  templateUrl: "./admin-menu.component.html",
  styleUrls: ["./admin-menu.component.scss"],
  encapsulation: ViewEncapsulation.None,
  animations: [listAnimations],
})
export class AdminMenuComponent implements AfterViewInit {
  @ViewChild("appDrawer") appDrawer: ElementRef;
  version = VERSION;
  private userPermission: Subscription;
  menuPermisions: any;
  navItems: NavItem[];

  constructor(
    private navService: NavService,
    public router: Router,
    private cdr: ChangeDetectorRef,
    public commonService: CommonService,
    private dynamicHeader: AdminDynamicHeaderService
  ) {}
  tempMenu: any[] = [];
  ngOnInit() {
    this.userPermission = this.dynamicHeader.permissions.subscribe(
      (Permissions) => {
        var p = {} as StringCollection;
        // var temp = JSON.parse(localStorage.getItem('userPermissions'));
        //   var d=this.menuPermisions?this.menuPermisions:
        if (Permissions) {
          for (var prop in Permissions) {
            var p = {} as StringCollection;
            var name = Permissions[prop].name;
            p.view = Permissions[prop].view;
            p.edit = Permissions[prop].edit;
            p.delete = Permissions[prop].delete;
            p.menu = Permissions[prop].menu;
            this.tempMenu[name] = p;
          }
          this.menuPermisions = this.tempMenu;
          this.initMenu();
        }

        // p.menu=true;
        // this.menuPermisions['AllPermision'] = p;
      }
    );
    this.userPermission = this.dynamicHeader.permissionsWhenPageReload.subscribe(
      (Permissions) => {
        var p = {} as StringCollection;
        if (Permissions) {
          this.menuPermisions = Permissions;
          this.initMenu();
        }
        // p.menu=true;
        // this.menuPermisions['AllPermision'] = p;
      }
    );
  }

  initMenu() {
    if (this.navItems ? this.navItems.length <= 0 : !this.navItems) {
      this.navItems = [
        {
          displayName: "Dashboard",
          iconName: "icon-dashboard",
          parentMenu: "dashboard",
          menuName: "dashboard",
          flag: false,
          permissionName: "AllPermision",
          route: "/app/admin/dashboard",
        },
        {
          displayName: "Customers",
          menuName: "customers",
          parentMenu: "customers",
          iconName: "icon-users",
          flag: false,
          permissionName: "CustomerManagement",
          route: '/app/admin/customers'
          // children: [
          //   {
          //     displayName: "Portal",
          //     menuName: "customers",
          //     parentMenu: "customers",
          //     flag: false,
          //     iconName: "icon-users",
          //     route: "/app/admin/customers",
          //     permissionName: "CustomerManagement",
          //   }           
          // ],
        },
        {
          displayName: "Permissions",
          menuName: "permissions",
          parentMenu: "permissions",
          flag: false,
          iconName: "icon-permission",
          permissionName: "Permissions",
          route: "/app/admin/permissions",
        },
        {
          displayName: "Requests",
          menuName: "request",
          parentMenu: "request",
          flag: false,
          permissionName: "Request",
          iconName: "icon-requests",
          children: [
            {
              displayName: "Help  Request",
              menuName: "requests",
              parentMenu: "request",
              permissionName: "HelpRequest",
              flag: false,
              iconName: "icon-file_icon",
              route: "/app/admin/requests",
            },
            {
              displayName: "Additional Financing",
              menuName: "additionalFinancing",
              parentMenu: "request",
              permissionName: "AdditionalFinancing",
              flag: false,
              iconName: "icon-money_icon",
              route: "/app/admin/requests/additional-financing",
            },
            {
              displayName: "Calculators",
              menuName: "calculator",
              parentMenu: "request",
              permissionName: "Calculators",
              iconName: "icon-calculatoricon",
              flag: false,
              route: "/app/admin/requests/calculator",
            },
            {
              displayName: "Signup Customers",
              menuName: "signupCustomers",
              parentMenu: "request",
              permissionName: "SignUpRequest",
              iconName: "icon-users",
              flag: false,
              route: "/app/admin/requests/signup-customers",
            },
          ],
        },
        {
          displayName: "Forms",
          iconName: "icon-forms",
          menuName: "forms",
          parentMenu: "forms",
          flag: false,
          permissionName: "FormsAndDocuments",
          route: "/app/admin/forms",
        },
        {
          displayName: "Email Triggers",
          menuName: "emailTriggers",
          parentMenu: "emailTriggers",
          permissionName: "EmailTriggers",
          flag: false,
          iconName: "icon-email-triggers",
          route: "/app/admin/email-triggers",
        },
        {
          displayName: "Non Portal Customers",
          menuName: "nonPortalCustomers",
          parentMenu: "nonPortalCustomers",
          permissionName: "CustomerManagement",
          flag: false,
          iconName: "icon-users",
          route: "/app/admin/non-portal-customers",
        },
        {
          displayName: "Banner Ads",
          menuName: "bannerAds",
          parentMenu: "bannerAds",
          permissionName: "BannerAds",
          flag: false,
          iconName: "icon-banner-ads font-size-46",
          route: "/app/admin/banners",
        },
        {
          displayName: "FAQ",
          menuName: "faq",
          parentMenu: "faq",
          iconName: "icon-faq",
          flag: false,
          permissionName: "FAQ",
          route: "/app/admin/faq",
        },
        {
          displayName: "Reports",
          iconName: "icon-shopping-list-1",
          parentMenu: "reports",
          flag: false,
          permissionName: "Reports",
          menuName: "reports",
          children: [
            {
              displayName: "Login Reports",
              menuName: "reports",
              parentMenu: "reports",
              iconName: "icon-shopping-list-1",
              flag: false,
              permissionName: "Reports",
              route: "/app/admin/reports/reports",
            },
            {
              displayName: "Payment Reports",
              menuName: "paymentReports",
              parentMenu: "reports",
              iconName: "icon-shopping-list-1",
              flag: false,
              permissionName: "Reports",
              route: "/app/admin/reports/payment-reports",
            },
          ],
        },
        {
          displayName: "Transaction Fee",
          iconName: "icon-finance_icon",
          parentMenu: "transactionFee",
          menuName: "transactionFee",
          flag: false,
          permissionName: "TransactionFee",
          route: "/app/admin/transactions",
        },
        {
          displayName: "NEC Users",
          menuName: "necUsers",
          parentMenu: "users",
          iconName: "icon-necuser",
          flag: false,
          permissionName: "NECUserManagement",
          route: "/app/admin/nec-users",
        },
      ];
    }

    this.navItems.forEach((element) => {
      element.flag = false;
      var flag = false;
      if (element.children && element.children.length) {
        element.children.forEach((el) => {
          el.flag = false;
          if (
            this.menuPermisions[el.permissionName]
              ? this.menuPermisions[el.permissionName].menu
              : el.permissionName == "AllPermision"
              ? true
              : false
          ) {
            flag = true;
            el.flag = true;
          }
        });

        if (flag) {
          element.flag = flag;
        }
      } else {
        if (
          this.menuPermisions[element.permissionName]
            ? this.menuPermisions[element.permissionName].menu
            : element.permissionName == "AllPermision"
            ? true
            : false
        ) {
          element.flag = true;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.navService.appDrawer = this.appDrawer;
    this.navService.openNav();
    this.cdr.detectChanges();

    this.router.events.subscribe((event) => {
      /* to expand the active menu when navigating through breadcrumbs */
      if (event instanceof NavigationEnd && this.navItems) {
        this.navItems.forEach((parent) => {
          if (parent.children) {
            parent.children.forEach((child) => {
              if (child.route == event.url) {
                this.commonService.expandedMenu = parent.menuName;
              }
            });
          }
        });
      }
    });
  }
}
export interface StringCollection {
  edit: boolean;
  delete: boolean;
  view: boolean;
  menu: boolean;
}
