import { Pipe } from '@angular/core';
@Pipe({
    name: 'truncate'
})
export class TruncateZero {
    transform(val) {
        if (val) {
          var res=val.toString().replace(/^0+/, '');
            if (res=='') {
                return 0;
            } else {
                return res;
            }
        }
       
    }
}
