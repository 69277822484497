import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageGuard } from '@shared/auth/parent-auth.gaurd';
import { QuicklinkModule, QuicklinkStrategy } from 'ngx-quicklink';


const routes: Routes = [
  { path: '', redirectTo: 'app', pathMatch: 'full', canActivate: [PageGuard] },
  {
    path: 'app',
    loadChildren: 'app/app.module#AppModule', //Lazy load account module
    data: { preload: true }
  }
];

@NgModule({
  imports: [QuicklinkModule,RouterModule.forRoot(routes, { useHash: true, preloadingStrategy: QuicklinkStrategy })], // for loading lazy modules in idle time ------ ,preloadingStrategy: PreloadAllModules
  exports: [RouterModule],
  providers: []
})
export class RootRoutingModule {}
