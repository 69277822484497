import { Component, OnInit, Input, SimpleChanges, HostListener, EventEmitter, Output, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material';
import { environment } from 'environments/environment';
import { enterAnimation } from '@shared/layout/animations';
import { ProfileEditComponent } from '../profile-edit/profile-edit.component';
import { ChangePasswordComponent } from '../change-password/change-password.component';
@Component({
  selector: 'app-common-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
  animations: [enterAnimation]
})
export class CommonProfileComponent implements OnInit {
  @Input() profileData;
  userId: number;
  @ViewChild(ProfileEditComponent)
  profileEditComponent: ProfileEditComponent;
  @ViewChild(ChangePasswordComponent)
  changePasswordComponent: ChangePasswordComponent;
  constructor(public dialog: MatDialog) {}
  // @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
  //   /* close all side panels on pressing escape key */
  //   this.closeSidePanel();
  // }

  DefaultImage = environment.baseUrl + 'Images/DefaultImages/default-user.jpg';
  baseImageUrl =environment.S3StorageService?"": environment.baseUrl + 'Images/UserProfile/';
  ngOnInit() {
    // console.log(this.profileData);
  }

  @Output() profileUpdated: EventEmitter<null> = new EventEmitter<null>();
  @Output() userProfileUpdated: EventEmitter<null> = new EventEmitter<null>();
  ngOnChanges(changes: SimpleChanges): void {
    this.profileData = changes['profileData'].currentValue;
    if (this.profileData) {
      this.userId = this.profileData.id;
      if (!environment.S3StorageService)
      {
        if (this.profileData.document) {
          this.profileData.document.url=environment.baseUrl + 'Images/UserProfile/'+this.profileData.document.thumbImageFileName
        }
      }
      else
      {
        if (this.profileData.document) {
          localStorage.setItem('S3BucketImagePath', this.profileData.document.url);
        }
      }
    }
  }
  refresCustomerUser() {
    this.userProfileUpdated.emit();
  }

  refreshData() {
    this.profileUpdated.emit();
  }
  openSidePanel(selector) {
    $('body').addClass('show-sidepanel');
    $('.side-panel-right.active').removeClass('active');
    $('#' + selector).addClass('active');
  }

  closeSidePanel() {
    $('body').removeClass('show-sidepanel');
    $('.side-panel-right').removeClass('active');
  }
}
