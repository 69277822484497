import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { UserDto, RoleList } from '@shared/interfaces/nec-user';
import { LoginService } from './login.service';
import { RestService } from './rest.service';
import {BannerAddDetails} from '@shared/interfaces/banner';
@Injectable({
    providedIn: 'root'
  })
export class BannerService extends RestService {

    constructor(_http: HttpClient, loginService: LoginService) {
        super(_http, loginService);
    }

    getBanner(params: HttpParams) {
        return this.GET('Banner/GetAll', params);
    }
    getBannerCategory() {
        return this.GET('Banner/GetAllBannerCategories');
    }
    AddBanner(bannerDetails:BannerAddDetails )
    {
        return this.POST('Banner/Create',bannerDetails);  
    }    
    UpdateBanner(bannerDetails:BannerAddDetails )
    {
        return this.PUT('Banner/Update',bannerDetails);  
    }
    deleteBanner(bannerId: number) {
        return this.DELETE('Banner/Delete?id=' + bannerId);
    }    
    GetBanner(bannerId:number) {
        return this.GET('Banner/Get?id='+bannerId);
    }    
    checkDuplicateName(calculatorId: number,name:string,type:number) {
         return this.POST('Banner/CheckDuplicateName?id=' + calculatorId+'&name='+name+'&type='+type, '');
    }  
    GetBannerImages() {
        return this.GET('Banner/GetBannerImages');
    } 
    
}
