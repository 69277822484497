import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpResponse, HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

import { LoginService } from './login.service';
import { RequestOptions } from '@angular/http';
import { environment } from 'environments/environment';
import { Router } from '@angular/router';
import { HttpParamsOptions } from '@angular/common/http/src/params';

export class RestService {
  static injector: Injector;
  private router: Router;
  readonly BaseUrl = environment.baseUrl + 'api';

  constructor(private _http: HttpClient, private loginService: LoginService) {
    this.router = RestService.injector.get(Router);
  }

  GET(url: string, params?: HttpParams): Observable<any> {
    const requestUrl = `${this.BaseUrl}/${url}`;
    return this._http.get<any>(requestUrl, { params: params }).pipe(catchError(err => this.handleError(err, this)));
  }

  POST(url: string, body, options?: any): Observable<any> {
    const requestUrl = `${this.BaseUrl}/${url}`;
    return this._http.post(requestUrl, body, options).pipe(catchError(err => this.handleError(err, this)));
  }

  PATCH(url: string, body): Observable<any> {
    const requestUrl = `${this.BaseUrl}/${url}`;
    return this._http.patch(requestUrl, body).pipe(catchError(err => this.handleError(err, this)));
  }

  DELETE(url: string): Observable<any> {
    const requestUrl = `${this.BaseUrl}/${url}`;
    return this._http.delete(requestUrl).pipe(catchError(err => this.handleError(err, this)));
  }

  PUT(url: string, body): Observable<any> {
    const requestUrl = `${this.BaseUrl}/${url}`;
    return this._http.put(requestUrl, body).pipe(catchError(err => this.handleError(err, this)));
  }

  private handleError(err: HttpErrorResponse, _this: any) {
    if (err.error && err.error.result) {
      var errorResult = err.error.result.errors[0];
      console.log('http error occurred');
      console.log(err.message);
      let errMsg = (errorResult.message || '').toString();
      let isInvalid = errMsg.toLocaleLowerCase().includes('invalid id');
      if (isInvalid) {
        _this.router.navigate(['app/invalid-page']);
      } else {
        console.log(errorResult.message);
        return throwError(errorResult);
      }
    } else {
      console.log('http error occurred');
      console.log(err.message);
      return throwError(err);
    }
  }
}
