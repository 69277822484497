import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FAQDto } from '@shared/interfaces/faq'
import { LoginService } from './login.service';
import { RestService } from './rest.service';
import { BehaviorSubject } from 'rxjs';
import { IResetPasswordDto } from '@shared/interfaces/users';
import * as CryptoJS from 'crypto-js';
import { key } from '@shared/common/constants';
import { CookieService } from 'ngx-cookie-service';

@Injectable()
export class AuthService extends RestService {
    private isFaqDeleted = new BehaviorSubject("default message");
    currentDeleteStatus = this.isFaqDeleted.asObservable();
    cookieValue = '';

    constructor(_http: HttpClient, loginService: LoginService, private cookieService: CookieService ) {
        super(_http, loginService);
    }

    login(loginDetails: any) {
        abp.auth.clearToken();
        return this.POST('TokenAuth/Authenticate', loginDetails);
    }
    
    signUp(signUpDetails: any) {
        return this.POST('CustomerSignUP/Create', signUpDetails);
    }

    forgotPassWord(userId:string) { 
         return this.GET('User/SendResetPasswordLink?userId='+userId);
    }

    forgotUserPassWord(email:string) { 
         return this.PUT('Account/ForgotPassword?email='+email,{});
    }

    getUserInfo(emailId:string) {
        return this.GET('User/GetUserByEmailId?input='+ emailId);
    }
    passwordNullSet(email:string)
    {
        return this.GET('User/SetPasswordNull?input='+ email);
    }
    getPermissions(id:number) {
        return this.GET('Role/GetRolePermissions?id='+id);
    }

    logout() {
        return this.GET('TokenAuth/Signout');
    }

    checkPassWordValidity(userId:any) {
        return this.GET('Account/PasswordValidityCheck?userId='+ userId)
    }
    
    activateUser(userId:any) {
        return this.GET('CustomerSignUP/ActivateUser?userId='+ userId)
    }
  
    resetPassWord(resetPassWordDto :IResetPasswordDto) {
        return this.POST('Account/ResetPassword',resetPassWordDto);
    }

    getUserPermissions() {
        return this.GET('Role/GetUserRolePermissions');
    }

    encryptUserDetails(user: any) {
        this.cookieService.delete('isRemember');
        if (user.rememberClient == true) {
            let encryptedUserDetails = CryptoJS.AES.encrypt(user.password, key).toString(); 
            let encEmail = btoa(user.userNameOrEmailAddress);
            let encPasswd = btoa(encryptedUserDetails);
            this.cookieService.set('UserAuthId', encPasswd);
            this.cookieService.set('emailId', encEmail);
            this.cookieService.set('isRemember', user.rememberClient ? 'true' : '');
        }
    }
    
    decryptUserDetails(encPasswd) {
        let result = CryptoJS.AES.decrypt(encPasswd, key);
        var decryptedData = result.toString(CryptoJS.enc.Utf8);
        return decryptedData;
    }
}
