import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userFilter',
  pure: false
})
export class UserFilterPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (!value || !args) {
        return value;
    }
    return value.filter(item => item.RoleId == args);
  }

}
